<template>
	<div>
		<PageTitle title="药品物流" fixed backTo="/logistics"></PageTitle>
		<div style="position: relative;width: 85%;margin: 15px auto auto auto;">
			<List :width="100">
				<ListItem :leftWidth="0" :rightWidth="100">
					<template #top>
						<p>订单详情</p>
					</template>
					<template #right>
						<van-divider :style="{ borderColor: '#D8D8D8', margin: '10px 0' }"></van-divider>
						<p>订单状态：{{item.wlzt}}</p>
						<p>物流单号：{{item.kddh}}
							<button class="copy-btn" v-show="item.kddh != '暂无'" v-clipboard:copy="item.kddh"
								v-clipboard:success="onCopy" v-clipboard:error="onError">复制单号
							</button>
						</p>
						<p>收货人：{{item.sjrxm}} {{item.phone}}</p>
						<p v-show="item.sjrxm != '来院自取'">
							收货地址：{{item.sjrsf}}&nbsp;{{item.sjrqs}}&nbsp;{{item.sjrxj}}&nbsp;{{item.sjrzj}}</p>
						<p v-show="item.sjrxm != '来院自取'">{{item.sjrxxdz}}</p>
						<van-divider :style="{ borderColor: '#D8D8D8', margin: '10px 0' }"></van-divider>
						<p>执业点：{{item.ksmc}}</p>
						<p>开方医生：{{item.ygxm}}</p>
						<van-divider :style="{ borderColor: '#D8D8D8', margin: '10px 0' }"></van-divider>
						<p class="colorForBlue">诊断：</p>
						<p class="colorForBlue" v-for="(diagnosis,diagnosisIndex) in item.diagnosisList" :key="'diagnos' + diagnosisIndex">
							{{diagnosisIndex + 1}}.{{diagnosis.jbmc}}
						</p>
						<van-divider :style="{ borderColor: '#D8D8D8', margin: '10px 0' }"></van-divider>
						<p class="colorForBlue">药品名称：</p>
						<div class="div-mt-5" v-for="(drug,index) in item.ypxx1List" :key="index">
							<p>{{index + 1}}.{{drug.ypmc}} ({{drug.yfgg}}) 共{{drug.ypsl}}{{drug.yfdw}}</p>
							<p style="margin-left: 10px;">用法：{{drug.cfbz}}，{{drug.xmmc}}</p>
						</div>
						<van-divider :style="{ borderColor: '#D8D8D8', margin: '10px 0' }"></van-divider>
						<div class="div-mt width-half">
							<div>
								<p>挂号费：</p>
							</div>
							<div>
								<p>￥{{item.ghf}}元</p>
							</div>
							<div>
								<p>药品费用：</p>
							</div>
							<div>
								<p>￥{{item.totalFee}}元</p>
							</div>
							<div>
								<p>药师审核费：</p>
							</div>
							<div>
								<p>{{item.ysshf}}</p>
							</div>
							<div>
								<p>快递费（顺丰快递）：</p>
							</div>
							<div>
								<p>{{item.kdf}}</p>
							</div>
						</div>
						<div style="float: right">
							<p style="font-size: 14px;color: #E56F47;">合计费用 ￥{{item.hjfy}}元</p>
						</div>
						<div style="margin-top: 30px">
							<p>开具时间：{{item.kfrq}}</p>
						</div>
					</template>
					<template #bottom>

					</template>
				</ListItem>
			</List>
		</div>
	</div>
</template>

<script>
	import PageTitle from '@/components/PageTitle.vue';
	import List from '@/components/home-page/List.vue';
	import ListItem from '@/components/home-page/ListItem.vue';
	import {
		GetDrug
	} from '@/api/user.js';


	export default {
		components: {
			PageTitle,
			List,
			ListItem,
		},
		data() {
			return {
				item: {},
			}
		},
		mounted() {
			GetDrug(this.$route.params.treatmentNum).then(
				(result) => {
					this.item = result;
					this.item.kfrq = this.$util.moment(this.item.kfrq).format('YYYY-MM-DD HH:mm');
				},
				() => {}
			);

		},
		methods: {
			onCopy() {
				this.$toast("复制成功");
			},
			onError() {
				this.$toast("复制失败");
			}
		}
	}
</script>

<style scoped="" lang="less">
	@import "../style/var.less";

	.copy-btn {
		padding: 0 10px;
		color: @white;
		background: @green;
		border: 1px @green;
		border-radius: 5px;
		margin-left: 10px;
	}

	.div-mt {
		margin-top: 10px;
	}

	.div-mt-5 {
		margin-top: 5px;
		color: @green;
	}

	.width-half {
		div {
			width: 50%;
			display: inline-block;
		}

		div:nth-child(2n) p {
			float: right;
			color: @gray-8;
		}

		div:nth-child(2n+1) p {
			float: left;
			color: @gray-6;
		}
	}

	.infoContainer {
		padding: 10px;

		p {
			margin: 0;
		}

		.top p {
			text-align: center;
			margin: 0 0 10px;
		}

		.right {
			p {
				font-size: @font-size-sm;
			}
		}
	}

	.colorForBlue {
		color: @green;
	}
</style>
